import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`EQを操作するときは、こうした「EQの九九」を毎回使うことになります。`}</p>
      <p>{`例えば、ボーカルの印象がこもっているとします。`}<br parentName="p"></br>{`
`}{`この場合、リファレンスのボーカルと比べて、`}<br parentName="p"></br>{`
`}{`自分のボーカルは九九のどこが違うのか？を考えます。`}</p>
      <p>{`「『肉厚』がありすぎるのか？`}<br parentName="p"></br>{`
`}{`『芯』が足りないのか？`}<br parentName="p"></br>{`
`}{`『サラサラ』が足りないのか？」`}</p>
      <p>{`と、九九を１つずつ比較して、`}<br parentName="p"></br>{`
`}{`「ここかな？」と思う九九の周波数を操作します。`}</p>
      <p>{`その結果が、「リファレンスに近づいたか、遠ざかったか」を判断し、`}<br parentName="p"></br>{`
`}{`もし近づいていれば「その操作は合っていた」ということになります。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      